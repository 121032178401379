import React from "react";
import { usePortfolioPageData } from "@staticQueries";
import { DefaultLayout } from "@layout";
import {
  Section,
  Container,
  RawText,
  Pattern,
  Triangle,
  Text,
  RichText,
} from "@atoms";
import {
  CopyBlock,
  ImageBlock,
  CopyImageBlock,
  Carousel,
  CarouselSlides,
  CompanyGrid,
  TestimonialFader,
} from "@composites";

const PortfolioPage = pageProps => {
  const {
    hero,
    featured,
    overview,
    companies,
    testimonials,
    prefooter,
    meta,
  } = usePortfolioPageData();

  return (
    <DefaultLayout {...pageProps} meta={meta} theme="light">
      {/* Hero */}
      <Section className="relative bg-gray-5" size="xl">
        <Container>
          <RawText items={hero.heading.raw} className="text-green-2 max-w-sm" />
        </Container>

        <Pattern
          orientation="bottom-left"
          size="sm"
          className="text-gray-3"
          doAnimation
        />
      </Section>

      {/* Featured */}
      <Section className="bg-white">
        <Pattern orientation="top-left" size="sm" className="text-gray-3" />

        <Container>
          <Text tag="label" className="block text-green-3 mb-4">
            {featured.subheading}
          </Text>

          <RawText items={featured.heading.raw} className="text-green-2" />
        </Container>

        <Carousel className="max-w-xxl mx-auto">
          {featured.companies.map(company => (
            <CarouselSlides.Featured key={company.id} {...company} />
          ))}
        </Carousel>

        <Pattern orientation="bottom-left" size="sm" className="text-gray-3" />
      </Section>

      {/* Overview */}
      <Section className="bg-green-1" size="xl">
        <Pattern orientation="top-left" size="sm" className="text-gray-2" />

        <Container>
          <div className="md:flex md:items-center">
            <div className="w-full md:w-1/2 md:pr-12 my-4">
              <RichText
                html={overview.intro.html}
                className="RichText--xl text-gray-4"
              />
            </div>

            <div className="w-full md:w-1/2 md:pl-12 my-4">
              <Text tag="label" className="block text-green-3 mb-4">
                {overview.heading}
              </Text>

              <RichText
                html={overview.description.html}
                className="text-gray-4"
              />
            </div>
          </div>
        </Container>

        <Pattern orientation="bottom-right" size="sm" className="text-gray-2" />
      </Section>

      {/* Companies */}
      <Section className="bg-white">
        <Pattern orientation="top-right" size="sm" className="text-gray-3" />

        <Container>
          <Text tag="label" className="block text-green-3 mb-4">
            {companies.subheading}
          </Text>

          <RawText
            items={companies.heading.raw}
            className="text-green-2 mb-8 md:mb-16"
          />

          <CompanyGrid companies={companies.companies} />
        </Container>
      </Section>

      {/* Prefooter/Testimonials */}
      <div className="relative bg-white">
        <Triangle orientation="bottom-right" className="text-gray-5" />

        <Section hasPaddingTop={false} allowOverflow>
          <TestimonialFader
            testimonials={testimonials}
            className="md:py-6 lg:py-12"
          />
        </Section>

        <Section hasPaddingTop={false}>
          <Container class="relative overflow-hidden w-full">
            <CopyImageBlock
              orientation="left"
              className="bg-green shadow-section"
              copy={({ style }) => (
                <div className="relative overflow-hidden">
                  <CopyBlock
                    {...prefooter}
                    preset="light"
                    className="relative z-10 px-6 pb-6 sm:px-8 sm:pb-8 md:p-16"
                    style={style}
                  />
                  <Pattern
                    orientation="bottom-left"
                    size="sm"
                    className="text-gray-2"
                  />
                </div>
              )}
              image={({ className, style }) => (
                <ImageBlock
                  image={prefooter.image}
                  className={className}
                  style={style}
                />
              )}
            />
          </Container>
        </Section>
      </div>
    </DefaultLayout>
  );
};

export default PortfolioPage;
